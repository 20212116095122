import React, { useState } from 'react';
import Cookies from 'js-cookie';
import disk from '../assets/disk.svg';

const YouTubePlayer = ({ videoId, isMobile }) => {
  const [consented, setConsented] = useState(false);

  if (isMobile) {
    var frameWidth = 425;
    var frameHeight = 250;  
  }
  else {
    var frameWidth = 853;
    var frameHeight = 505;
  }
  const height = frameHeight;
  const width = frameWidth;

  // Function to handle user's consent to cookies
  const handleConsent = () => {
    // You can save the consent information to cookies/local storage here
    // For simplicity, we'll just set it in state for this example
    setConsented(true);
  };

  return (
    <div>
        <iframe
          width={width}
          height={height}
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
    </div>
  );
};

export default YouTubePlayer;
