import spotify from '../assets/Spotify.png';
import tiktok from '../assets/TikTok.png';
import youtube from '../assets/YouTube.png';
import instagram from '../assets/Instagram.png';
import { useLocation, NavLink } from 'react-router-dom';

function Menu() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div>
      <div className='menu'>
        <NavLink
          to="/home"
          className={`menu-item ${currentPath === '/home' || currentPath === '/' ? 'selected' : ''}`}
        >
          <span className='menu-item-text'>Home</span>
        </NavLink>
        
        <a
          href="https://merch.botticellibaby.com/tickets"
          rel="noopener noreferrer"
          className="menu-item"
        >
          <span className='menu-item'>Tickets</span>
        </a>

        <a
          href="https://merch.botticellibaby.com/merch"
          rel="noopener noreferrer"
          className="menu-item"
        >
          <span className='menu-item'>Merch</span>
        </a>
  
      </div>
      <div className='social'>
        <div className={'social-item'}>
          <a href="https://www.instagram.com/botticelli.baby/?hl=en">
            <img src={instagram} alt="Instagram" />
          </a>
        </div>
        <div className={'social-item'}>
          <a href="https://open.spotify.com/artist/6h971vhkhZ8eM5SQjHi0Ta?si=4yVzOp-4Qh-Legmxr1XMUw">
            <img src={spotify} alt="Spotify" />
          </a>
        </div>
        <div className={'social-item'}>
          <a href="https://www.youtube.com/@botticellibaby6930">
            <img src={youtube} alt="YouTube" />
          </a>
        </div>
        <div className={'social-item'}>
          <a href="https://www.tiktok.com/@botticelli_baby">
            <img src={tiktok} alt="TikTok" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Menu;
