import './AppDesktop.css';

import HomeDesktop from './pages/Desktop/HomeDesktop';
import ImprintDesktop from './pages/Desktop/ImprintDesktop';
import TicketsDesktop from './pages/Desktop/TicketsDesktop'
import PrivacyPolicyDesktop from './pages/Desktop/PrivacyPolicyDesktop';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState } from 'react';


function App() {  

  const [consentedPreferences, setConsentedPreferences] = useState(false);
  const [consentedStatistics, setConsentedStatistics] = useState(false);
  const [consentedMarketing, setConsentedMarketing] = useState(false);

  return (
    <div>
      <div>
        <CookieBanner
          message="Please accept cookies for the best website experience. By clicking 'Accept All', you agree to the use of all cookies. You can change or withdraw your cookie consent at any time: "
          showAcceptSelectionButton = {true}
          policyLink = {"/privacy-policy"}
          styles={{
            dialog: { backgroundColor: '#E397AF',
                      borderRadius: '8px',
                      border: '1px solid black',
                    },
            message:{ color: 'black',
                    fontFamily: 'Faustina Regular, sans-serif',
                    fontSize: '15px',
                    },
                    
            optionLabel: { color: 'black',
                        fontFamily: 'Faustina Regular, sans-serif',
                        fontSize: '13px',
                        marginLeft: '20px',
                        },
            policy: { color: 'black',
                    fontFamily: 'Faustina Regular, sans-serif',
                    fontSize: '13px',
                    justifyContent: 'left',
                    display: 'flex',                 
                    },
            buttonWrapper: { display: 'flex',
                            gap: '15px',
                            justifyContent: 'center',
                            marginBottom: '5px',
                            },
            selectPane: { display: 'flex', 
                        flexDirection: 'row',  
                        gap: '10px',
                        marginLeft: '0px',
                        justifyContent: 'center',
                        },

            checkbox: { width: '13px',
                        height: '13px',
                        marginRight: '5px',
                        justifyContent: 'center',
                        alignItems: 'center', 
                        },
            button: {
                    borderRadius: '10px',
                    fontFamily: 'Faustina Regular, sans-serif',
                    width: '150px',
                    height: '30px',
                    border: '1px solid black', 
                    boxShadow: '0px 4px 4px rgba(4, -4, 4, 0.75)',
                    onmouseenter: 'fontStyle: italic;',
                    onmouseleave: 'fontStyle: normal;',
                    },
                
          }}
          onAcceptPreferences = {() => { 
            setConsentedPreferences(true);
          }}
          onAcceptStatistics = {() => {
            setConsentedStatistics(true);
          }}
          onAcceptMarketing = {() => {
            setConsentedMarketing(true);
          }}
        />
    </div>
      <BrowserRouter>
        <Routes>
          <Route index element = { <HomeDesktop />} />
          <Route path = "/home" element = { <HomeDesktop/>} />
          <Route path = "/tickets" element = { <TicketsDesktop/>} />
          <Route path = "/imprint" element = { <ImprintDesktop/>} />
          <Route path = "/privacy-policy" element = { <PrivacyPolicyDesktop/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;
