import header from '../assets/header.svg';


function Header(){
    return(
        <div className="App-header">        
            <img src={header} alt="Botticelli Baby" style={{marginTop: '18px'}}/>
        </div>
    );
}

export default Header;  