import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import React, { useState } from 'react';
import header from '../../assets/header.svg';
import bugerIconOpen from '../../assets/BurgerIconOpen.svg'
import bugerIconClose from '../../assets/BurgerIconClose.svg'
import { useLocation, NavLink } from 'react-router-dom';
import MenuMobile from "./MenuMobile";
import TourTableMobile from "./TourTableMobile";


function TicketsMobile(){

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const handleBurgerClick = () => {
      setIsMenuOpen(!isMenuOpen);
    };


  return(
          <div className = "app-mobile">
            <header>
                <img src={header} alt="header" style={{marginTop: '20px', width: '100%'}}/>
                <div class="menu-icon" onClick={handleBurgerClick}>
                    <img 
                    src={isMenuOpen ? bugerIconClose : bugerIconOpen} 
                    alt="menu" 
                    style={{width: '30px'}}
                    onClick={() => setIsMenuOpen(true)}
                    />   
                </div>
            </header>
            <main>
            <TourTableMobile />
              {isMenuOpen &&
              <MenuMobile />}              
            </main>
          </div>
  );
}
export default TicketsMobile;