import React, { useEffect, useState } from 'react';
import { parse } from 'date-fns';

function Table() {
  const [tourTableHead, setTourTableHead] = useState('');
  useEffect(() => {
    fetch('/tour-table/TourTableHeadMobile.txt')
      .then(response => response.text())
      .then(data => {
        setTourTableHead(data);
      });
  }, []);

  const [tourTableBottom, setTourTableBottom] = useState('');
  useEffect(() => {
    fetch('/tour-table/TourTableBottom.txt')
      .then(response => response.text())
      .then(data => {
        setTourTableBottom(data);
      });
  }, []);

  const [entries, setEntries] = useState([]);
  useEffect(() => {
    const loadEntries = async () => {
      try {
        const response = await fetch('/tour-table/TourTable.csv');
        const data = await response.text();
        const rows = data.split('\n');
        const headers = rows[0].trim().split(';');

        const results = [];
        for (let i = 1; i < rows.length; i++) {
          const row = rows[i].split(';');
          if (row.length === headers.length) {
            const entry = {};
            for (let j = 0; j < headers.length; j++) {
              entry[headers[j]] = row[j];
            }
            results.push(entry);
            console.log(entry);
          }
        }

        setEntries(results);
      } catch (error) {
        console.error('Error loading CSV:', error);
      }
    };

    loadEntries();
  }, []);

  const pinkStyle = { color: '#E397AF' };
  const whiteStyle = { color: '#FFFFFF' };
  const pinkSeperator = <span style={pinkStyle}>|</span>;
  const whiteSeperator = <span style={whiteStyle}>|</span>;

  return (
    <div>
      <pre>
        <div className="tour-table-mobile-frame-top">
          <pre>{tourTableHead}</pre>
        </div>
        <div className="tour-table-mobile-text">
          <pre>
          {entries.map((entry, index) => {
              const entryDateString = String(entry.Date);
              const entryDateStringParts = entryDateString.split('.');

              const day = parseInt(entryDateStringParts[0]);
              const month = parseInt(entryDateStringParts[1]);
              const year = 2000 + parseInt(entryDateStringParts[2]);

              let entryDate; // Define entryDate within the scope
              let isPast;
              try {
                entryDate = parse(`${year}-${month}-${day}`, 'yyyy-M-d', new Date());
                console.log("Parsed Date:", entryDate);
                isPast = entryDate < new Date();
              } catch (error) {
                console.log("Invalid Date");
              }
              
              const entryItem = isPast ? 'crossed-out-row': 'normal-item';                
              const ticketItem = isPast ? 'crossed-out-row': 'link-item';  

              const combined = (entry.Venue + ', '  + entry.City);
              return ( // add this return keyword
                <div className="row-item" key={index}>
                  <span className={entryItem}>{pinkSeperator} {entry.Date} | </span>
                  <span><a href={entry.URL} className={ticketItem}>{combined.padEnd(35)}</a></span> 
  
                  {index < entries.length - 1 && (
                    <React.Fragment>
                      {pinkSeperator} {pinkSeperator}{"\n"}
                      {pinkSeperator}----------|------------------------------------{pinkSeperator} {pinkSeperator}{"\n"}
                    </React.Fragment>
                  )}
                  {index === entries.length - 1 && (
                    <React.Fragment>
                      {pinkSeperator} {pinkSeperator}{"\n"}
                    </React.Fragment>
                  )}
                </div>
              ); // end of return statement
          })}
          </pre>
        </div>
        <div className="tour-table-mobile-frame-bottom">
          <pre>
            |__________{whiteSeperator}____________________________________|/
          </pre>
        </div>
      </pre>
    </div>
  );
  
}

export default Table;
