import spotify from '../../assets/Spotify.png';
import tiktok from '../../assets/TikTok.png';
import youtube from '../../assets/YouTube.png';
import instagram from '../../assets/Instagram.png';
import { useLocation, NavLink } from 'react-router-dom';

function MenuMobile() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="sidebar-menu">
        <ul>
            <NavLink 
                to="/home"
                className={`menu-item-mobile ${currentPath === '/home' ? 'selected' : ''}`}
                >
                <span className='menu-item-mobile'>Home</span>
            </NavLink>
        </ul>

        <ul>
        <a
                href="https://merch.botticellibaby.com/tickets"
                rel="noopener noreferrer"
                className="menu-item-mobile"
                >
                    <span className='menu-item-mobile'>Tickets</span>
            </a>
        </ul>

        <ul>
            <a
                href="https://merch.botticellibaby.com/merch"
                rel="noopener noreferrer"
                className="menu-item-mobile"
                >
                    <span className='menu-item-mobile'>Merch</span>
            </a>
        </ul>

        <ul>
            <NavLink 
                to="/imprint"
                className={`menu-item-mobile ${currentPath === '/imprint' ? 'selected' : ''}`}
                >
                <span className='menu-item-mobile'>Imprint </span>{"\n"}
            </NavLink>
        </ul>

        <ul>
            <a
                href="https://www.handshake-booking.com/en/artist/botticelli-baby/"
                rel="noopener noreferrer"
                className="menu-item-mobile"
                >
                    <span className='menu-item-mobile'>Booking</span>{"\n"}
            </a>
        </ul>

        <ul>
            <a
                href="https://botticellibaby.bandcamp.com/track/1-30"
                rel="noopener noreferrer"
                className="menu-item-mobile"
                >
                    <span className='menu-item-mobile'>Label</span>{"\n"}
            </a>
        </ul>

        <ul>
            <a
                href="https://schubertmusic.com/"
                rel="noopener noreferrer"
                className="menu-item-mobile"
                >
                    <span className='menu-item-mobile'>Publisher</span>{"\n"}
            </a>
        </ul>

        <ul>
            <a
                href="https://mailchi.mp/13f2d3d6f649/botticelli-baby-newsletter"
                rel="noopener noreferrer"
                className="menu-item-mobile"
                >
                    <span className='menu-item-mobile'>Newsletter</span>{"\n"}
            </a>
        </ul>

        <ul>
            <a
                href="mailto:contact@botticellibaby.com"
                rel="noopener noreferrer"
                className="menu-item-mobile"
                >
                    <span className='menu-item-mobile'>Contact</span>{"\n"}
            </a>
        </ul>
        <ul>
            <div className='social-mobile'>
                <div className={'social-mobile-item'}>
                <a href="https://www.instagram.com/botticelli.baby/?hl=en">
                    <img src={instagram} alt="Instagram" />
                </a>
                </div>
                <div className={'social-mobile-item'}>
                <a href="https://open.spotify.com/artist/6h971vhkhZ8eM5SQjHi0Ta?si=4yVzOp-4Qh-Legmxr1XMUw">
                    <img src={spotify} alt="Spotify" />
                </a>
                </div>
                <div className={'social-mobile-item'}>
                <a href="https://www.youtube.com/@botticellibaby6930">
                    <img src={youtube} alt="YouTube" />
                </a>
                </div>
                <div className={'social-mobile-item'}>
                <a href="https://www.tiktok.com/@botticelli_baby">
                    <img src={tiktok} alt="TikTok" />
                </a>
                </div>
            </div>
        </ul>
    </div>
    );
}

export default MenuMobile;