import axios from 'axios'
import fileDownload from 'js-file-download'
import { useNavigate, useLocation, NavLink } from 'react-router-dom';


function Footer(){
    const location = useLocation();
    const currentPath = location.pathname;
  
    const navigate = useNavigate();

return(
    <div>
        <div className = "App-footer">
            <NavLink 
                to="/imprint"
                className={"footer-menu-item"}
                >
                <span className='menu-item-text'>Imprint</span>
            </NavLink>
            <a span className='footer-menu-item'style={{textDecoration: 'none', display: "table-cell"}} href= "https://www.handshake-booking.com/en/artist/botticelli-baby/">Booking</a>
            <a span className='footer-menu-item'style={{textDecoration: 'none', display: "table-cell"}} href= "https://uniquerecords1988.bandcamp.com/artists">Label</a>
            <a span className='footer-menu-item'style={{textDecoration: 'none', display: "table-cell"}} href= "https://roofmusic.de/">Publisher</a>
            <a span className='footer-menu-item'style={{textDecoration: 'none', display: "table-cell"}} href= "https://mailchi.mp/13f2d3d6f649/botticelli-baby-newsletter">Newsletter</a>
            <a span className='footer-menu-item'style={{textDecoration: 'none', display: "table-cell"}} href='mailto:contact@botticellibaby.com'>Contact</a>
            <a span className='footer-menu-item'style={{textDecoration: 'none', display: "table-cell"}} href='http://botticellibaby.com/EPK.zip'>EPK</a>
        </div>
    </div>
    )
}

export default Footer;