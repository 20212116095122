import React, { useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';

import header from '../../assets/header.svg';
import bugerIconOpen from '../../assets/BurgerIconOpen.svg'
import bugerIconClose from '../../assets/BurgerIconClose.svg'
import MenuMobile from './MenuMobile';


function Imprint() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname;
  
    const handleBurgerClick = () => {
        setIsMenuOpen(!isMenuOpen);
      };
      

    return(
        <div class="app-mobile">            
            <header>
                <img src={header} alt="header" style={{marginTop: '20px', width: '100%'}}/>
            <div class="menu-icon" onClick={handleBurgerClick}>
                <img 
                src={isMenuOpen ? bugerIconClose : bugerIconOpen} 
                alt="menu" 
                style={{width: '30px'}}
                onClick={() => setIsMenuOpen(true)}
                />   
            </div>
            </header>
            <main>
                {isMenuOpen &&
                    <MenuMobile />} 
                <div className="content-container">            
                    <h1>Impressum</h1>
                
                    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                    <p>Botticelli Baby GbR<br />
                    Am Pumpwerk 7<br />
                    44879 Bochum</p>

                    <p><strong>Vertreten durch:</strong><br />
                    Marlon B&ouml;sherz<br />
                    Alexander Niermann<br />
                    J&ouml;rg Marc Buttler<br />
                    Tom Hellenthal<br />
                    Maximilian Melchior Wehner<br />
                    Christian Scheer<br />
                    Lucius Nawothnig</p>

                    <h2>Kontakt</h2>
                    <p>E-Mail: contact@botticellibaby.com</p>

                    <h2>Redaktionell verantwortlich</h2>
                    <p>Christian Scheer</p>

                    <h2>EU-Streitschlichtung</h2>
                    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
                </div>
            </main>
        </div>
    );
}

export default Imprint;