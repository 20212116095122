import AppDesktop from './AppDesktop';
import AppMobile from './AppMobile';
import HttpsRedirect from 'react-https-redirect';

function App() {  
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div>
      <HttpsRedirect>
        {isMobile ? <AppMobile /> : <AppDesktop />}
      </HttpsRedirect>
    </div>
  );
}

export default App;
