import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import TourTable from "./TourTable";
import React, { useState, useEffect } from 'react';

function Tickets(){

  return(
    <div className = "App-home"> 
      <Header />
      <div className='rowC'>
        <Menu />
            <div className="tour-table" style={{margin: 'auto'}}>
              <TourTable />
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Tickets; 