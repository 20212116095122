import disk from '../../assets/disk.svg';
import Animated from '../../assets/BlueDots.svg';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';
import CookieBanner from 'react-cookie-banner';
import YouTubePlayer from '../../components/YouTubePlayer';
import Cookies from 'js-cookie';
import FallingImages from './FallingImages.js';
import { useEffect, useState } from 'react';
import {motion} from 'framer-motion';

function Home() {

    return (
        <div className = "App-home">
            <Header />
            <FallingImages />
            <div className='rowC'>
                <Menu />
                <div className="App-logo">
                    <img src={disk} alt="Botticelli Baby" style={{marginTop: '18px'}}/>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;