import React, { useState } from 'react';
import disk from '../../assets/disk.svg';
import header from '../../assets/header.svg';
import bugerIconOpen from '../../assets/BurgerIconOpen.svg'
import bugerIconClose from '../../assets/BurgerIconClose.svg'
import Cookies from 'js-cookie';

import MenuMobile from './MenuMobile';
import { useLocation, NavLink } from 'react-router-dom';
import YouTubePlayer from '../../components/YouTubePlayer';

function Home() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname;
  
    const handleBurgerClick = () => {
        setIsMenuOpen(!isMenuOpen);
      };

    return (
        <div class="app-mobile">            
            <header>
                <img src={header} alt="header" style={{marginTop: '20px', width: '100%'}}/>
                <div class="menu-icon" onClick={handleBurgerClick}>
                    <img 
                    src={isMenuOpen ? bugerIconClose : bugerIconOpen} 
                    alt="menu" 
                    style={{width: '30px'}}
                    onClick={() => setIsMenuOpen(true)}
                    />   
                </div>
            </header>
            {
                Cookies.get('rcl_marketing_consent') ? (
                    <div className= 'youtubePlayer-mobile'>
                    <YouTubePlayer videoId="3a7wmca8IWU" isMobile={true}/>
                    </div>
                    ) : (
                    <div className='youtubeError-mobile'>
                        <p style={{ color: 'white', textAlign: 'center' }}> ヽ(｀⌒´ﾒ)ノ ヽ(｀⌒´ﾒ)ノ ヽ(｀⌒´ﾒ)ノ <br></br>Consent to the use of cookies and you will see the best music video that you have ever seen! <br></br>ヽ(｀⌒´ﾒ)ノ </p>
                    </div>
                ) 
            }
            {isMenuOpen &&
            <MenuMobile />}       
        </div>
    );
}

export default Home;