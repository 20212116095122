import '../../AppDesktop.css'
import komet_green from '../../assets/Komet_green.png';
import komet_rosa from '../../assets/Komet_rosa.png';

const FallingImages = () => {
  // Generate a list of falling images in random positions of both komet_green and komet_rosa 
  const imageList = Array(100).fill().map((_, index) => (
    <img
      key={index}
      className="falling-image"
      src={index % 2 === 0 ? komet_green : komet_rosa}
      style={{
        left: `${Math.random() * 100}vw`,
        animationDuration: `${Math.random() * 2 + 3}s`,
        zIndex: `${Math.floor(Math.random() * 2)}`,
        // make them disappear at the bottom to avoid screen overflow
        bottom: `${Math.random() * 100 + 100}vh`,
        // make the disappearing smooth
        transition: 'bottom 5s',
      }}
      alt={`Falling Image ${index + 1}`}
    />
  ));

  return (
    <div className="falling-images-container">
      {imageList}
    </div>
  );
};

export default FallingImages;
