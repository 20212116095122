import './AppMobile.css';

import HomeMobile from './pages/Mobile/HomeMobile';
import ImprintMobile from './pages/Mobile/ImprintMobile';
import TicketsMobile from './pages/Mobile/TicketsMobile'
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';

import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {  
  return (
    <div>
        <CookieBanner
          justifyContent = 'bottom'
          message="Please accept cookies for the best website experience. By clicking 'Accept All', you agree to the use of all cookies. You can change or withdraw your cookie consent at any time: "
          showAcceptSelectionButton = {true}
          policyLink = {"/privacy-policy"}
          styles={{
            dialog: { with: '100%',
                      backgroundColor: '#E397AF',
                      borderRadius: '8px',
                      border: '1px solid black',
                    },
            message:{ color: 'black',
                    fontFamily: 'Faustina Regular, sans-serif',
                    fontSize: '15px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    },
                    
            optionLabel: { color: 'black',
                        fontFamily: 'Faustina Regular, sans-serif',
                        fontSize: '13px',
                        marginLeft: '20px',
                        },
            policy: { color: 'black',
                    fontFamily: 'Faustina Regular, sans-serif',
                    fontSize: '13px',
                    justifyContent: 'left',
                    display: 'flex',                 
                    },
            buttonWrapper: { display: 'flex',
                            gap: '15px',
                            justifyContent: 'center',
                            marginBottom: '5px',
                            },
            selectPane: { display: 'flex', 
                        flexDirection: 'row',  
                        gap: '10px',
                        marginLeft: '0px',
                        justifyContent: 'center',
                        },

            checkbox: { width: '13px',
                        height: '13px',
                        marginRight: '5px',
                        justifyContent: 'center',
                        alignItems: 'center', 
                        },
            button: {
                    borderRadius: '10px',
                    fontFamily: 'Faustina Regular, sans-serif',
                    width: '150px',
                    height: '30px',
                    border: '1px solid black', 
                    boxShadow: '2px 4px 4px rgba(4, -4, 4, 0.75)',
                    onmouseenter: 'fontStyle: italic;',
                    onmouseleave: 'fontStyle: normal;',
                    },
                
          }}
          onAcceptPreferences = {() => { 
            // load your preferences trackers here
          }}
          onAcceptStatistics = {() => {
            // load your statistics trackers here
          }}
          onAcceptMarketing = {() => {
            // load your marketing trackers here
          }}
        />
      <BrowserRouter>
        <Routes>
          <Route index element = { <HomeMobile />} />
          <Route path = "/home" element = { <HomeMobile/>} />
          <Route path = "/tickets" element = { <TicketsMobile/>} />
          <Route path = "/imprint" element = { <ImprintMobile/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;
